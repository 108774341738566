<style scoped>
.cms-inner-page{
  padding:70px 0
}
.cms-inner-page .cms-content h2{
  color:#04092c;
  margin:0;
  font-family:'Heebo', sans-serif!important;
  font-weight:600;
  font-size:24px;
  margin-bottom:15px
}
.cms-inner-page .cms-content ul{
  padding-left:30px;
  margin-bottom:20px;
  margin-top:-10px
}
.cms-inner-page .cms-content ul li{
  margin-bottom:10px;
  list-style:disc
}
.cms-inner-page .cms-content ul li ul{
  margin-top:10px
}
.cms-inner-page .cms-content ul li ul li{
  list-style:decimal
}
.cms-inner-page .cms-content li strong,.cms-inner-page .cms-content p,.cms-inner-page .cms-content ul li{
  font-family:'Heebo', sans-serif;
  color:#04092c;
  font-size:15px;
  line-height:24px;
  letter-spacing:0
}
.cms-inner-page .cms-content p.cms-date{
  padding-bottom:15px;
  border-bottom:1px solid #ccc;
  margin-bottom:30px
}
.cms-inner-page .cms-content p strong{
  font-size:17px;
  color:#04092c;
  font-family:'Heebo', sans-serif;
  letter-spacing:0;
  font-weight: 600;
}
.cms-inner-page .cms-content p a,.cms-inner-page .cms-content p a strong,.cms-inner-page .cms-content ul li span a{
  color:#ea3708 !important;
  font-weight:700
}
.cms-inner-page .cms-content table{
  border: 1px solid #dedede;
}
.cms-inner-page .cms-content table tbody tr th{
  font-size:14px;
  line-height:20px;
  background-color: #f7f7f7;
}
.cms-inner-page .cms-content table tbody tr td{
  font-size:13px;
  line-height:20px
}
.cms-inner-page .cms-content table tbody tr td,.cms-inner-page .cms-content table tbody tr th{
  color:#04092c;
  border: 1px solid #dedede;
}
.innerpage-banner{
  height:150px;
  position: relative;
}
.innerpage-banner:after{
  content:'';
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  display:inline-block;
  background-color:rgb(0 0 0/45%);
  width:100%;
  z-index:2;
}
.innerpage-banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.innerpage-banner .section-header{
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex:1;
  flex:1;
  height:100%;
  max-width:100%;
  position:absolute;
  width:100%;
  z-index:3;
  justify-content:center;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
}
.innerpage-banner .section-header h2{
  color:#fff;
  font-family:'Heebo', sans-serif !important;
  font-weight:700;
  font-size: 36px;
  text-align: center;
  margin-bottom: 0px;
}
@media (min-width: 1024px){
  .innerpage-banner{
    height:180px
  }
}
@media (max-width: 1280px){
  .innerpage-banner .container{
    max-width:100%
  }
}
@media (max-width: 1024px){
  .cms-inner-page{
    padding:50px 0
  }
  .cms-inner-page .cms-content p,.cms-inner-page .cms-content ul li{
    font-size:14px
  }
  .cms-inner-page .cms-content p strong{
    font-size:15px
  }
  .cms-inner-page .cms-content h2{
    font-size:20px
  }
  .cms-table-responsive{
    overflow-x:auto
  }
  .cms-table-responsive table{
    width:800px!important
  }
  .innerpage-banner .section-header h2{
    font-size:32px
  }
}
@media (max-width: 768px) {
  .innerpage-banner .section-header h2{
    font-size:24px
  }
}
@media (max-width: 767px) {
  .innerpage-banner .container{
    max-width:100%
  }
}
@media (max-width: 575px){
  .cms-inner-page{
    padding:35px 0
  }
  .cms-inner-page .cms-content h2{
    font-size:18px
  }
  .cms-inner-page .cms-content p, .cms-inner-page .cms-content ul li {
    font-size: 13px;
    line-height: 20px;
  }
  .cms-inner-page .cms-content p strong{
    font-size:14px
  }
}
</style>
<template>
  <section class="innerpage-banner contact-page">
    <v-lazy-image src="../images/privacy-policy.webp" alt="image" width="1920" height="235"></v-lazy-image>
    <div class="section-header">
      <h2>Privacy Policy</h2>
      <BannerSubText />
    </div>
  </section>
  <section class="terms-privacy-wrapper cms-inner-page">
    <div class="container">
    <div class="cms-content">
      <p class="vdp-type-body cms-date"><strong>Last updated on May 9, 2022</strong></p>
      <p class="vdp-type-body">This Privacy Policy (this "<strong>Privacy Policy</strong>") applies to the website located at <router-link to="/"> www.Litseats.com</router-link> (the “<strong>Website</strong>”) and its associated webpages each of which are owned and operated by Lit Seats LLC (together with its parents, subsidiaries, and affiliated companies, "<strong>Lit Seats</strong>", "<strong>we</strong>", or "<strong>us</strong>"). This Privacy Policy describes how Lit Seats collects, shares, secures and uses the personal information you provide when you visit or use our Website. When we refer to "<strong>personal information</strong>" in this Privacy Policy, we mean information that directly identifies you (such as your name, postal address, or email address) or data that could be used to indirectly identify you. In order to purchase or list tickets for sale, you will be required to register an account. Kindly note that any consent you provide will be entirely voluntary. However, if you do not accept this policy, then you should not use, and are not authorized to use the Website.</p>
      <h2 class="vdp-type-title-sm">What information do we collect and why?</h2>
      <p class="vdp-type-body">We and our service providers collect a variety of types of information, much of which is based on your interaction with our Website, including:</p>
      <ul class="vdp-type-body--list">
        <li class="vdp-type-list-item"><strong>Device and browsing information,</strong> including IP address, referral website, browser and device type, operating system version, webpage navigation, aggregated usage, performance data, and other information about your activities on this and other websites (collectively, "<strong>automatically collected information</strong>"). This information is collected for the purpose of determining the best way to serve you including, but not limited to, showing you events you are most interested in, crediting marketing partners with attribution appropriately, for marketing and re-marketing purposes, and for internal analytics;</li>
        <li class="vdp-type-list-item"><strong>Contact Information,&nbsp;</strong> such as your name, email address, mailing address, zip code, postal code, and phone number. This information is used for creating an account, verifying customer's identity when he or she corresponds about his or her order, delivery, fraud verification, for marketing and re-marketing purposes, and for internal analytics;</li>
        <li class="vdp-type-list-item"><strong>Listing information of tickets listed for sale on our Website,</strong>  including event, location of the event, quantity, section and row of the tickets, and physical location of the tickets. This information is collected for the purpose of providing accurate listings;</li>
        <li class="vdp-type-list-item"><strong>Purchase and sale information</strong> relating to tickets bought or sold through our Website , including billing address, credit card or other payment information, links, PayPal or other third party payment information, name and address of a gift recipient, and name and phone number of a third person who will pick up items for you This information is used for creating an account, verifying customer’s identity when he or she corresponds about his or her order, delivery, and fraud verification;</li>
      </ul>
      <h2 class="vdp-type-title-sm">How do we collect information?</h2>
      <p class="vdp-type-body">We collect information through our Website and through email and other communications and interactions with you. We also obtain information about you and from third parties. We collect and obtain information:</p>
      <ul class="vdp-type-body--list">
        <li class="vdp-type-list-item"><strong>You provide,</strong> such as when you visit or use our Website, purchase tickets or list tickets for sale, create an account with us, subscribe to our emails, contact or otherwise communicate with us, or register for other activities on our Website;</li>
        <li class="vdp-type-list-item"><strong>From third parties,</strong> such as other users of our Website (for example, when another user purchases tickets for you as a gift, or when another user designates you to pick up tickets on his or her behalf), service providers that we use, including but not limited to Provider (as defined in the Sales Terms), ticket vendors and resellers, publicly available databases or third parties from whom we have purchased data, marketing partners, and other third parties that we choose to collaborate or work with;</li>
        <li class="vdp-type-list-item"><strong>From social media platforms and networks</strong> that you use in connection with our Website, or that share or allow you to share information with us, such as Facebook. For example, if you use functionalities, plugins, widgets, or tools from social media platforms or networks in connection with our Website (for example, to share purchases and other content from our Website with your friends and followers on social media), we will collect the information that you share with us, or that those social media platforms or networks share with us; and</li>
        <li class="vdp-type-list-item"><strong>Using cookies and other automatic collection methods.</strong> For example, we, our affiliates and/or third parties we work with may collect information from the computer, tablet, phone, or other device that you use to access our Website, or that you use to open an email from us. This collection includes Automatically Collected Information, and generally does not include personal information that directly identifies you unless you provide it through our Website or you choose to share it with us. Automatic collection methods we use include cookies, web beacons, tags, scripts, and similar technologies.</li>
      </ul>
      <h2 class="vdp-type-title-sm">How do we use information?</h2>
      <p class="vdp-type-body">We use the Information that we collect and obtain to conduct our business and to provide you with the products, services, and experiences that you request or that we think may be of interest to you. We also may combine any or all of the information that we collect or obtain with other information that we collect or obtain, including but not limited to information obtained from third parties with which we choose to work. We use information:</p>
      <ul class="vdp-type-body--list">
        <li class="vdp-type-list-item"><strong>To provide you with services,</strong> including to administer the Website, fulfill your orders and process your payments, pay you if your tickets sell, calculate shipping options, verify your identity and protect the integrity of the ticket sales through our Website, process, maintain and service your account, send you relevant newsletters, offers, and promotions, and provide you with products and services that you request or that we think may be of interest to you. We may use your IP address to infer your city and state location in order to recommend nearby athletic or entertainment events that may of interest to you. This could include sending you push notifications or alerts regarding such nearby events;</li>
        <li class="vdp-type-list-item"><strong>To contact you</strong> and deliver information to you that, in some cases, is targeted to your interests. These communications may contain links for preference management and, where appropriate, unsubscribe links should you decide you do not want to receive further communications.</li>
        <li class="vdp-type-list-item"><strong>To respond to you,</strong> when you place an order, correspond with us by email or otherwise communicate with us, make a request or inquiry, enter a contest or sweepstakes, complete a survey; or share a comment or concern;</li>
        <li class="vdp-type-list-item"><strong>For personalization</strong> of your experiences with us, including to remember your interests and preferences, customize the listings and services we share with you, track and categorize your activity and interests on our Website (including by identifying the different devices you use), and make your shopping experience with us more relevant;</li>
        <li class="vdp-type-list-item"><strong>For marketing and advertising, </strong> to send you marketing and advertising communications, to segment and build audiences and engage in interest-based advertising, to provide social media features, and to personalize the marketing and advertising that we show you;</li>
        <li class="vdp-type-list-item"><strong>For analytics,</strong> to understand how you use our Website, analyze our Website traffic, to gather demographic information about our individual users and aggregate user base, to determine the methods and devices used to access our Website, and to improve our Website and associated services.</li>
        <li class="vdp-type-list-item"><strong>For our business purposes,</strong> to operate and improve upon our business and lawful business activities, to identify new customers, to update, expand, and analyze our records, for research, to determine your satisfaction with our Website and services, to detect and prevent fraud or misuse of our services, to enforce, apply or comply with any of our terms or policies, including but not limited to this Privacy Policy, and for any other business purpose that is permitted by law; and</li>
        <li class="vdp-type-list-item"><strong>For legal, contractual and safety purposes,</strong> including to defend or protect us, our customers, you, or third parties from harm or in legal proceedings, protect our rights, protect our security and the security of our customers, employees, and property, respond to court orders, lawsuits, subpoenas, and government requests, address legal and regulatory compliance, comply with contracts and other agreements to which we are a party, and notify you of changes to our terms, policies, or practices.</li>
      </ul>
      <p class="vdp-type-body">If you provide us personal information about others, we may disclose that information to certain third parties such as sports teams for their marketing purposes. When you provide us with personal information about other parties, we will assume that you have obtained the consent of such other parties to do so.</p>
      <h2 class="vdp-type-title-sm">Will my personal and account information be provided to any other party?</h2>
      <p class="vdp-type-body">We restrict access to your personal information to those who need access to use it as set forth in this Privacy Policy. Your personal and account information will never be sold or shared to third parties, except under the following circumstances:</p>
      <ul class="vdp-type-body--list">
        <li class="vdp-type-list-item">With service providers that perform certain functions. These third parties may only use personal information as necessary to provide these services to us and not for promotional purposes. These include the following:
        <ul class="vdp-type-body--list">
          <li class="vdp-type-list-item">For the purpose of processing payments.</li>
          <li class="vdp-type-list-item">For the purpose of fulfilling your order, delivering your tickets, whether that is electronically or physically, and ensuring you successfully access your seats.</li>
          <li class="vdp-type-list-item">For the purpose of preventing fraudulent purchases and sales through our Website.</li>
          <li class="vdp-type-list-item">For the purpose of analyzing data to provide you a better customer experience.</li>
          <li class="vdp-type-list-item">For the purpose of marketing to inform you about events or special promotions that may be of interest to you.</li>
        </ul>
        </li>
        <li class="vdp-type-list-item">With select business partners so that they can provide you with special offers, promotional materials, and other materials that may be of interest to you. This includes our partners that are listed on our Website. The information we give these partners may include your name, email address, phone number, and billing address. Lit Seats is not responsible for the privacy practices of these business partners;</li>
        <li class="vdp-type-list-item">To respond to subpoenas, lawful requests by public authorities, court orders, or other legal process, as required by law, or to establish or exercise our legal rights or defend against legal claims;</li>
        <li class="vdp-type-list-item">When we believe it is necessary to protect the rights, property, or safety of Lit Seats, our users, or others, including for fraud protection;</li>
        <li class="vdp-type-list-item">In connection with a sale of some or all of our business or a merger with another company. You will be notified via email and/or a prominent notice on our Website of any change in ownership; or</li>
        <li class="vdp-type-list-item">With your prior consent to do so.</li>
      </ul>
      <p class="vdp-type-body">You may direct us not to share your personal information with third parties (other than our service providers), at any time, by sending an email to <span itemprop="email"><a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a></span>, or by following the removal instructions in the communication that you receive.</p>
      <h2 class="vdp-type-title-sm">Links to 3rd Party Sites</h2>
      <p class="vdp-type-body">Our Website includes links to other websites whose privacy practices may differ from those of Lit Seats. If you submit personally identifiable information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit.</p>
      <h2 class="vdp-type-title-sm">Frames</h2>
      <p class="vdp-type-body">Some of our pages utilize framing techniques to serve content from our partners while preserving the look and feel of our website. Please be aware that you are providing your personal information to these third parties and not to Lit Seats.</p>
      <h2 class="vdp-type-title-sm">Security and Retention</h2>
      <p class="vdp-type-body">We use Secure Socket Layers ("SSL") encryption technology where your personal and account information is required (such as a credit card number). Our website is certified by Norton Secured, which verifies that <router-link to="/"> www.Litseats.com</router-link> is authentic and that we use SSL security. To view our VeriSign certificate, click on the image of the closed lock or the solid key at the bottom of your browser window. Our website security information will appear.</p>
      <p class="vdp-type-body">All transaction information is processed under encrypted channels.</p>
      <p class="vdp-type-body">We follow generally accepted industry standards to protect your Personal Information, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security. We will keep your personal information only for as long as necessary for the purposes for which it was collected, to provide you with products or services, to carry out legitimate business interests, and where otherwise required or permitted by law. If you have any questions about security on our website, you can email us at <span itemprop="email"><a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a></span>.</p>
      <h2 class="vdp-type-title-sm">Browsers Do Not Track Signals</h2>
      <p class="vdp-type-body">Some browsers have incorporated "Do Not Track" (DNT) features that can send a signal to the websites you visit indicating that you do not wish to be tracked. There is not yet a common understanding of how to interpret the DNT signal. We currently do not respond to web browser DNT signals.</p>
      <h2 class="vdp-type-title-sm">Children's Privacy</h2>
      <p class="vdp-type-body">Our website is not directed to or intended to be used by individuals under the age of 16. We do not knowingly request or collect personal information from any person under 16 years of age without prior verifiable parental consent. If you believe that your child under the age of 16 has submitted personal information to us in connection with our website, and without prior verifiable parental consent, please email us at <span itemprop="email"><a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a></span> so that we can take steps to delete the personal information that she or he provided as required in accordance with applicable law.</p>
      <h2 class="vdp-type-title-sm">International Users</h2>
      <p class="vdp-type-body">If you provide personal information through our website, you agree that the personal information will be transferred to and processed in the United States of America and any other country or jurisdiction at our sole discretion. The laws that apply to the use and protection of personal information in the United States or other countries or jurisdictions in which we transfer or process personal information may be different than the laws and protections in your country. By using our website or app, you agree to the transfer and processing of your information in accordance with US law. In the event you wish to withdraw consent, please email <span itemprop="email"><a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a></span> to delete your account and then cease using our services. Those who access or use our website from jurisdictions outside the United States do so at their own choice and risk and are solely responsible for compliance with local law. While we take certain steps to safeguard your personal information, please note that the United States has <strong>NOT</strong> been deemed by the European Commission to ensure an adequate level of protection for personal data. Accordingly, the level of safeguards and legal protections provided in the United States or other non-European countries from which you may access our website may not be as stringent as that under European Union privacy standards or the privacy laws of some other countries, possibly including your home jurisdiction.</p>
      <h2 class="vdp-type-title-sm">Your Choices</h2>
      <p class="vdp-type-body">We offer you choices about how certain personal information is used. Your choices include:</p>
      <ul class="vdp-type-body--list">
        <li class="vdp-type-list-item"><strong>Social Media Platforms and Networks.</strong> We encourage you to review your privacy options and settings with the social media platforms and networks you use to understand what choices you have about sharing information from those platforms and networks with us.</li>
        <li class="vdp-type-list-item"><strong>Email Choices.</strong> You can opt out of marketing or advertising emails by contacting us at <span itemprop="email"><a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a></span> or by utilizing the "unsubscribe" link or following other instructions noted in marketing or advertising emails you receive from us. Please note that opting out of receiving marketing or advertising emails from us will not apply to transactional communications or legal notices, for example, emails about your orders or about changes to our terms, policies, or business practices.</li>
      </ul>
      <h2 class="vdp-type-title-sm">Changes to Privacy Policy</h2>
      <p class="vdp-type-body">We may make changes to this Privacy Policy, and may apply any changes to information previously collected, as permitted by law. When we make material changes to this Privacy Policy, we will notify you by posting the updated Privacy Policy on our website, and we will update the effective date at the top of this Privacy Policy prior to the change becoming effective. By continuing to use our Website after being notified of material changes to this Privacy Policy, you agree that the terms of this Privacy Policy as of the effective date will apply to information previously collected or collected in the future.</p>
      <h2 class="vdp-type-title-sm">How can I give you feedback or contact you?</h2>
      <p class="vdp-type-body">You can reach us by email at <a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a> or by writing to us using the contact details below, or you can call us toll-free at 888-403-5097.</p>
      </div>
    </div>
  </section>
</template>

<script>
// import axios from 'axios'
// import _ from 'lodash'
import VLazyImage from 'v-lazy-image'
import BannerSubText from './../../components/layouts/BannerSubText.vue'

export default {
  name: 'Privacy Policy',
  components: {
    VLazyImage,
    BannerSubText
  },
  inject: {
    eventCategories: {
      from: 'headerMenuItems'
    }
  },
  beforeCreate () {
    document.title = 'Privacy Policy | ' + process.env.VUE_APP_NAME
  },
  data () {
    return {
      appName: process.env.VUE_APP_NAME,
      phoneNumberTollFree: process.env.VUE_APP_CONTACT_US_TOLL_FREE_NUMBER,
      phoneNumberInternational: process.env.VUE_APP_CONTACT_US_INTERNATIONAL_NUMBER,
      contactEmail: process.env.VUE_APP_CONTACT_US_EMAIL
    }
  },
  methods: {
    searchEvents: function (event) {
      if (this.$refs.searchEventInput.value) {
        this.$router.push({
          path: 'events',
          query: { q: this.$refs.searchEventInput.value }
        })
      }
    }
  }
}
</script>
